@keyframes notification-entering {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes notification-exiting {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.notificationEntering {
  animation-duration: var(--motion-duration-medium-1);
  animation-name: notification-entering;
  animation-timing-function: var(--motion-easing-standard);
}

.notificationExiting {
  animation-duration: var(--motion-duration-short-4);
  animation-name: notification-exiting;
  animation-timing-function: var(--motion-easing-standard);
}

/* stylelint-disable-next-line media-query-no-invalid */
@media screen(medium) {
  .notification {
    display: inline-flex;
  }

  .notificationText {
    max-width: 60ch;
    min-width: 40ch;
  }
}
