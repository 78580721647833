.region {
  --navigation-bar-spacing: var(--spacing-0);
  --fab-spacing: var(--spacing-0);
  --bottom-spacing: calc(
    var(--spacing-4) + var(--navigation-bar-spacing) + var(--fab-spacing)
  );

  bottom: var(--bottom-spacing);
  inset-inline: 0;
  padding-inline: var(--spacing-container);
  position: fixed;
  z-index: 2;
}

/* stylelint-disable-next-line selector-class-pattern */
:global(body:has(.navigation-bar)) .region {
  --navigation-bar-spacing: var(--navigation-bar-height, var(--spacing-0));
}

:global(body:has(.fab)) .region {
  --fab-spacing: calc(
    var(--fab-container-height, var(--spacing-0)) + var(--spacing-4)
  );

  /* stylelint-disable-next-line media-query-no-invalid */
  @media screen(medium) {
    --fab-spacing: var(--spacing-0);
  }
}
